import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Layout from "../modules/layout";
import SEO from "../modules/seo";
import Event from "../components/eventmaske/event";
import PageHeader from "../components/pageHeader";

const EventV2 = ({ pageContext }) => {
  const {
    name,
    node_locale,
    settings,
    title,
    subtitle,
    sessions,
    formSections,
    additionalInfo,
    image,
    location,
    format,
  } = pageContext;
  const siteSettings = settings;

  const [tags, setTags] = useState([]);

  useEffect(() => {
    const tagTitles = [];
    if (format?.title) {
      tagTitles.push(format.title);
    }
  
    if (location && location.length > 0) {
      location.forEach(item => {
        if (item.title) {
          tagTitles.push(item.title);
        }
      });
    }

    setTags(tagTitles);
  }, []);
  
  return (
    <Layout siteSettings={siteSettings}>
      <SEO
        title={`${siteSettings.siteName} - ${name}`}
        description={siteSettings.siteDescription.siteDescription}
        author={siteSettings.siteAuthor}
      />
      <PageHeader
        title={title}
        classname={'eventmaske'}
        splitTitle={false}
        headerSubtitle={subtitle}
        images={[image]}
        tags={tags}
      />
      <Container fluid className={"mt-4 mb-4"}>
        <Event
          title={title}
          additionalInfo={additionalInfo}
          sessions={sessions}
          formSections={formSections}
          node_locale={node_locale}
        />
      </Container>
    </Layout>
  );
};

export default EventV2;