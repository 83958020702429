/* Global imports */
import React from "react";
import PropTypes from "prop-types";

/* Local imports */

/* Component definition */
const RadioButton = ({
  className,
  text,
  checked,
  onChange,
  name,
  disabled,
  value,
}) => {
  return (
    <label
      className={`radioButton ${
        disabled ? "radioButton--disabled" : ""
      } ${className}`}
    >
      {text}
      <input
        type="radio"
        checked={checked}
        onChange={onChange}
        name={name}
        disabled={disabled}
        value={value}
      />
      <span className="checkmark"></span>
    </label>
  );
};
/* PropTypes */
RadioButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string
};
RadioButton.defaultProps = {
  className: "",
  checked: false,
  disabled: false,
};

/* Local utility functions */

/* Styles */

export default RadioButton;
