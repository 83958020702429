import { Link } from "gatsby";
import { getValue } from "../../helper/translation";
import React from "react";

const ConfirmationModal = ({ onClose, microcopy }) => {
  return (
    <div className="registration-confirmation-modal-outter">
      <div className="registration-confirmation-modal">
        <div className="header">
          <img src="/check-green.svg" alt="check-icon" className="check-icon" />
          <h3>
            {getValue("label.registrationConfirmationHeadline", microcopy)}
          </h3>
          <img
            src="/green-cross.svg"
            alt="cross-icon"
            className="close-confirmation"
            onClick={() => onClose(false)}
            role="button"
          />
        </div>
        <p className="text">
          {getValue("label.registrationConfirmationText", microcopy)}
        </p>
        <Link to="/" className="button button--primary">
          {getValue("actions.backToHome", microcopy)}
        </Link>
      </div>
    </div>
  );
};

export default ConfirmationModal;
