import React from "react";
import PropTypes from "prop-types";
import Input from "./input";
import { Col, Row } from "react-bootstrap";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Textarea from "./textarea";
import Checkbox from "./checkbox";
import RadioAndSelect from "./radioAndSelect";
import Select from "./select";

const FormSection = ({
  order,
  title,
  isASubmitFormSection,
  description,
  inputs,
  onChange,
  formInputs,
}) => {
  const inputsGroupedByRow = [];
  let currentRow = [];

  const handleSelectChange = (e, inputName) => {
    onChange(inputName, e.value);
  };

  const handleCheckboxChange = (e, inputName) => {
    e.target.setCustomValidity("");
    onChange(inputName, e.target.checked);
  };

  const formatType = type => {
    switch (type) {
      case "Input text":
        return "text";
      case "Input email":
        return "email";
      default:
        return type.toLowerCase();
    }
  };

  if (inputs) {
    inputs.forEach((input, index) => {
      const currentRowWidth = currentRow.reduce(
        (totalWidth, currentInput) => totalWidth + currentInput.width,
        0
      );

      if (currentRowWidth + input.width <= 100) {
        currentRow.push(input);
      } else {
        inputsGroupedByRow.push(currentRow);
        currentRow = [input];
      }

      if (index === inputs.length - 1) {
        inputsGroupedByRow.push(currentRow);
      }
    });
  }

  const setCustomValidityMessage = e => {
    if (e.target.validity.typeMismatch) {
      if (e.target.type === "email") {
        e.target.setCustomValidity("Enter an email address");
      }
    } else if (e.target.validity.valueMissing) {
      if (e.target.type === "checkbox") {
        e.target.setCustomValidity("Select this tickbox");
      } else {
        e.target.setCustomValidity("Fill out this field");
      }
    } else {
      e.target.setCustomValidity("");
    }
  };

  const renderCustomInput = ({
    id,
    name,
    label,
    checkboxWithLink,
    type,
    placeholder,
    required,
    options,
    onChange,
    value,
    mandatory,
    checkboxLabel,
  }) => {
    const inputName = name || label;
    switch (type) {
      case "select":
        return (
          <Select
            label={label}
            name={inputName}
            onValueChange={handleSelectChange}
            options={options
              ?.split("|")
              .map(option => ({ value: option, label: option }))}
            value={{
              value: formInputs[inputName],
              label: formInputs[inputName],
            }}
          />
        );
      case "textarea":
        return (
          <Textarea
            label={label}
            id={id}
            required={required}
            name={inputName}
            placeholder={placeholder}
            value={formInputs[inputName]}
            onChange={onChange}
          />
        );
      case "checkbox":
        return (
          <Checkbox
            text={checkboxWithLink || label}
            name={inputName}
            required={required}
            onToggle={e => handleCheckboxChange(e, inputName)}
            checked={formInputs[inputName]}
            onInvalid={setCustomValidityMessage}
          />
        );
      case "radio + select":
        return (
          <RadioAndSelect
            label={label}
            name={inputName}
            options={options}
            onChange={onChange}
          />
        );
      default:
        return (
          <Input
            key={id}
            id={id}
            name={inputName}
            label={label}
            checkboxWithLink={checkboxLabel}
            type={formatType(type)}
            placeholder={placeholder}
            required={mandatory}
            options={options}
            onChange={onChange}
            value={formInputs[inputName]}
            onInvalid={setCustomValidityMessage}
          />
        );
    }
  };

  const renderedRows = inputsGroupedByRow.map((row, rowIndex) => (
    <Row key={`row-${rowIndex}`}>
      {row.map(input => (
        <Col key={`col-${input.id}`} xs={12} className={`width-${input.width}`}>
          {renderCustomInput({
            key: input.id,
            id: input.id,
            name: input.name,
            label: input.label,
            checkboxWithLink: input.checkboxLabel,
            type: formatType(input.type),
            placeholder: input.placeholder,
            required: input.mandatory,
            options: input.options,
            onChange: onChange,
            value: formInputs[input.name],
            mandatory: input.mandatory,
            checkboxLabel: input.checkboxLabel,
          })}
        </Col>
      ))}
    </Row>
  ));

  return (
    <Row className="formSection">
      <Col>
        {isASubmitFormSection && <hr />}
        {title && !isASubmitFormSection && (
          <h3 className="formSectionTitle">
            <span className="formSectionIcon">{order}.</span> {title}
          </h3>
        )}
        {description &&
          description.json &&
          documentToReactComponents(description.json)}
        {inputs && renderedRows}
      </Col>
    </Row>
  );
};

FormSection.propTypes = {
  order: PropTypes.number,
  title: PropTypes.string,
  isASubmitFormSection: PropTypes.bool,
  description: PropTypes.object,
  inputs: PropTypes.array,
  onChange: PropTypes.func,
  formInputs: PropTypes.object,
};
FormSection.defaultProps = {
  title: "",
  inputs: [],
  data: [],
};
export default FormSection;
