/* Global imports */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import RadioButton from "./radioButton";
import Select from "./select";


/* Local imports */

/* Component definition */
const RadioAndSelect = ({
  label,
  onChange,
  options
}) => {
  const groups = options.split(";;");
  const defaultGroupSplit = groups[0].split("::");
  const defaultRadioState = defaultGroupSplit[0];
  const defaultSelectState = {
    'value': defaultGroupSplit[1].split("|")[0],
    'label': defaultGroupSplit[1].split("|")[0]
  }
  
  const [radioState, setRadioState] = useState(defaultRadioState);
  const [selectState, setSelectState] = useState(defaultSelectState);

  useEffect(() => {
    setSelectState(getFirstOptionByRadioLabel(radioState))
  }, [radioState])

  const onChangeSelect = (e) => {
    setSelectState({ "value": e.value, "label": e.value })
    onChange(label, `${radioState} - ${e.value}`);
  }

  const getFirstOptionByRadioLabel = (targetRadioLabel) => {
    for (const group of groups) {
      const [radioLabel, optionsString] = group.split('::');
  
      if (radioLabel === targetRadioLabel) {
        const options = optionsString.split('|');
        return {
          value: options[0],
          label: options[0],
        };
      }
    }
    return null;
  };

  const handleRadioChange = (e) => {
    setRadioState(e.target.value)

    let firstSelectValue = ""
    groups.forEach(group => {
      if(group.includes(e.target.value)) {
        firstSelectValue = group.split("::")[1].split("|")[0]
      }
    })

    setSelectState({ "value": firstSelectValue, "label": firstSelectValue })
    onChange(label, `${e.target.value} - ${firstSelectValue}`);
  }

  return (
    <>
      <label className="input-label w-100">{label}</label>
      <Row className="mt-0">
        {groups.map(group => {
          const [radioLabel, labelOptions] = group.split("::");
          return (
            <Col xs={12} lg={6} className="radioContainer" key={radioLabel}>
              <RadioButton
                name={label}
                onChange={handleRadioChange}
                text={radioLabel}
                value={radioLabel}
                checked={radioState === radioLabel}
              />
              <Select
                disabled={radioLabel !== radioState}
                name={label}
                onValueChange={e => onChangeSelect(e, label)}
                options={labelOptions?.split("|").map(option => ({ "value": option, "label": option }))}
                value={radioLabel === radioState && selectState || { "value": labelOptions?.split("|")[0], "label": labelOptions?.split("|")[0] }}
              />
            </Col>
          );
        })}
      </Row>
    </>
  );
};

/* PropTypes */
RadioAndSelect.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.string,
};

RadioAndSelect.defaultProps = {
  value: "",
};

/* Local utility functions */

/* Styles */

export default RadioAndSelect;
