import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Modal, Row } from "react-bootstrap";
import Session from "./session";
import Checkbox from "../checkbox";
import FormSection from "../formSection";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getValue } from "../../helper/translation";
import { useStaticQuery } from "gatsby";
import ConfirmationModal from "./confirmationModal";

const Event = ({ title, additionalInfo, sessions, formSections, node_locale }) => {
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [formInputs, setFormInputs] = useState({EventName: title});
  const [isSuccessModalShow, setIsSuccessModalShow] = useState(false);

  useEffect(() => {
    formSections.forEach((formSection) => {
      if (formSection.inputs) {
        formSection.inputs.forEach((input) => {
          setFormInputs((prev) => {
            return { ...prev, [input.name || input.label]: "" };
          });
        });
      }
    });
  }, [formSections]);

  const { microcopy } = useStaticQuery(graphql`
    {
      microcopy: allContentfulMicrocopy {
        edges {
          node {
            node_locale
            key
            value
          }
        }
      }
    }
  `);

  const localizedMicrocopy = {};

  microcopy.edges
    .map(edge => edge.node)
    .forEach(node => {
      if (node.node_locale === node_locale) {
        localizedMicrocopy[node.key] = node.value;
      }
    });

  const toggleSession = e => {
    const value = e.target.value;

    if (e.target.checked) {
      const sessionSelected = sessions.filter(item => item.name === value);
      setSelectedSessions([
        ...selectedSessions,
        { session: value, attendance: sessionSelected[0].type[0] },
      ]);
    } else {
      setSelectedSessions(
        selectedSessions.filter(session => session.session !== value)
      );
    }
  };

  const toggleAllSessions = e => {
    if (e.target.checked) {
      setSelectedSessions(
        sessions.map(session => ({
          session: session.name,
          attendance: session.type[0],
        }))
      );
    } else {
      setSelectedSessions([]);
    }
  };

  function editAttendanceBySession(targetSession, newAttendance) {
    const updatedSessions = selectedSessions.map(session => {
      if (session.session === targetSession) {
        return { ...session, attendance: newAttendance };
      }
      return session;
    });

    return setSelectedSessions(updatedSessions);
  }

  const onChangeInput = (inputName, inputValue) => {
    setFormInputs(prev => {
      return { ...prev, [inputName]: inputValue };
    });
  };

  const handleAttendance = e => {
    editAttendanceBySession(e.target.name, e.target.value);
  };

  const onSubmitForm = e => {
    e.preventDefault();

    const allSessionsFormated = sessions.map(session => {
      const selItem = selectedSessions.find(selItem => selItem.session === session.name);
      return { [session.name]: selItem ? selItem.attendance : "" };
    });

    const data = {
      ...Object.assign({}, ...allSessionsFormated),
      ...formInputs,
    };

    // Remove submit input field from list
    const keys = Object.keys(data);
    const lastKey = keys.pop();
    delete data[lastKey];

    const options = {
      method: "POST",
      headers: {
        CustomerToken: process.env.GATSBY_FORM_PROCESSING_TOKEN,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    fetch(process.env.GATSBY_FORM_PROCESSING_URL, options)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Response Error : ${response.statusText}`);
        } else {
          setIsSuccessModalShow(true);
        }
      })
      .catch(error => {
        throw new Error(`Error : ${error}`);
      });
  };

  return (
    <Row className="eventmaskeEvent">
      <Col>
        {additionalInfo && additionalInfo.json && (
          <div className="additionalInfo">
            {documentToReactComponents(additionalInfo.json)}
          </div>
        )}
        <Checkbox
          onToggle={toggleAllSessions}
          checked={selectedSessions.length === sessions.length}
          text={getValue("eventmaske.allSessions", localizedMicrocopy)}
        />
        <Row className="sessionsContainer mx-0">
          {sessions.map(session => (
            <Session
              key={session.id}
              title={session.name}
              startDate={session.startDate}
              endDate={session.endDate}
              hasSameSchedule={session.hasSameSchedule}
              description={session.description}
              attendance={session.type}
              sessionsSelected={selectedSessions}
              toggleSession={toggleSession}
              onChange={handleAttendance}
            />
          ))}
        </Row>

        <Row>
          <Col className="formSectionContainer">
            <form onSubmit={onSubmitForm}>
              {formSections.map((formSection, index) => (
                <FormSection
                  key={formSection.id}
                  order={index + 1}
                  title={formSection.title}
                  description={formSection.description}
                  inputs={formSection.inputs}
                  isASubmitFormSection={formSection.isASubmitFormSection}
                  onChange={onChangeInput}
                  formInputs={formInputs}
                />
              ))}
            </form>
          </Col>
        </Row>

        {isSuccessModalShow && (
          <ConfirmationModal
            onClose={() => setIsSuccessModalShow(false)}
            microcopy={localizedMicrocopy}
          />
        )}
      </Col>
    </Row>
  );
};

Event.propTypes = {
  additionalInfo: PropTypes.object,
  subtitle: PropTypes.string,
  sessions: PropTypes.array,
  formSections: PropTypes.array,
};
Event.defaultProps = {
  title: "",
  subtitle: "",
  sessions: [],
};
export default Event;
