import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '../checkbox';
import { Col, Row } from 'react-bootstrap';
import RadioButton from '../radioButton';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import moment from 'moment';

const Session = ({title, startDate, endDate, hasSameSchedule, description, attendance, toggleSession, onChange, sessionsSelected}) => {

  const startDateObject = moment(startDate);
  const endDateObject = moment(endDate);

  const formatTitle = () => {
    const [start, end] = title.split(":");
    return (<h3>{start}:<br/>{end}</h3>)
  }

  const formatDate = () => {
    if(hasSameSchedule) {
      return `xx.${startDateObject.format('MM.YYYY')}`;
    } else {
      return startDateObject.format('DD.MM.YYYY')
    }
  }

  return (<Row className='eventmaskeSession mx-0 w-100'>
    <Checkbox
      name={title}
      onToggle={toggleSession}
      checked={sessionsSelected && sessionsSelected.some(item => item.session === title)}
      value={title}
    />
    <Col md={12} lg={3}>
      {formatTitle()}
      <p>{formatDate()} | {startDateObject.format('hh:mm A')} - {endDateObject.format('hh:mm A')}</p>
    </Col>
    <Col>
      {description && description.json && documentToReactComponents(description.json)}
    </Col>
    <Col xs={4} md={3}>
      {attendance.length > 1 && attendance.map(option => (
        <RadioButton
          disabled={sessionsSelected && !sessionsSelected.some(item => item.session === title)}
          name={title}
          onChange={onChange}
          text={option}
          value={option}
          checked={sessionsSelected?.some(item => item.session === title && item.attendance === option)}
        />
      ))}
    </Col>
    
  </Row>);
}

Session.propTypes = {
  title: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  hasSameSchedule: PropTypes.bool,
  description: PropTypes.object,
  attendance: PropTypes.array,
  onToggle: PropTypes.func,
  onChange: PropTypes.func,
  sessionsSelected: PropTypes.array
}
Session.defaultProps = {
  title: "",
  description: "",
}
export default Session