/* Global imports */
import React from "react";
import PropTypes from "prop-types";

/* Local imports */

/* Component definition */
const Input = ({
  className,
  id,
  name,
  label,
  value,
  placeholder,
  type,
  hintText,
  errorText,
  warningText,
  onChange,
  disabled,
  required,
  onInvalid,
}) => {
  const getModifier = () => {
    if (disabled) {
      return "input--disabled";
    }
    if (errorText) {
      return "input--error";
    } else if (warningText) {
      return "input--warning";
    }
    return "";
  };
  const getMessage = () => {
    return errorText || warningText || hintText || "";
  };

  return (
    <>
      {label && type != "submit" && (
        <label
          htmlFor={id}
          className={`input-label ${required ? "is-required" : ""}`}
        >
          {label}
        </label>
      )}
      <input
        type={type}
        name={name}
        id={id}
        className={`input ${getModifier()} ${className}`}
        value={type === "submit" ? label : value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={e => {
          e.target.setCustomValidity("");
          onChange(name, e.target.value);
        }}
        required={required}
        onInvalid={onInvalid}
      />
      <span className="message">{getMessage()}</span>
    </>
  );
};

/* PropTypes */
Input.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf([
    "text",
    "password",
    "email",
    "select",
    "checkbox",
    "radio + select",
    "textarea",
    "submit",
  ]),
  hintText: PropTypes.string,
  errorText: PropTypes.string,
  warningText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onInvalid: PropTypes.func,
};

Input.defaultProps = {
  className: "",
  type: "text",
  disabled: false,
  value: "",
};

/* Local utility functions */

/* Styles */

export default Input;
