/* Global imports */
import React from "react";
import PropTypes from "prop-types";

/* Local imports */

/* Component definition */
const Textarea = ({
  id,
  name,
  label,
  value,
  placeholder,
  onChange,
  disabled,
  required
}) => {
  return (<>
    {label && <label htmlFor={id} className={`input-label ${required ? "is-required" : ""}`}>{label}</label>}
    <textarea name={name} id={id} className="input" placeholder={placeholder} onChange={e => onChange(name, e.target.value)} disabled={disabled} value={value}>{value}</textarea>
  </>)
};

/* PropTypes */
Textarea.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.string,
};

Textarea.defaultProps = {
  disabled: false,
  value: "",
};

/* Local utility functions */

/* Styles */

export default Textarea;
